import type { Nullable } from '~/types'

let googlemaps: {
  autocomplete: google.maps.places.AutocompleteService
  geocoding: google.maps.Geocoder
  map: typeof google.maps.Map
  marker: typeof google.maps.marker.AdvancedMarkerElement
}

export default async function (ignoreRouteCheck = false) {
  if (import.meta.server) {
    return
  }

  const apiKey = useRuntimeConfig().public.google.mapsApiKey
  const getRouteBaseName = useRouteBaseName()
  const route = useRoute()

  if (!ignoreRouteCheck && 'callback' === getRouteBaseName(route)) {
    return
  }

  if (!googlemaps) {
    const { Loader } = await import('@googlemaps/js-api-loader')

    const loader = new Loader({
      apiKey,
      libraries: ['places', 'geocoding'],
    })

    const places = loader.importLibrary('places')
    const geocoding = loader.importLibrary('geocoding')
    const maps = loader.importLibrary('maps')
    const marker = loader.importLibrary('marker')

    const { AutocompleteService } = await places
    const { Geocoder } = await geocoding
    const { Map } = await maps
    const { AdvancedMarkerElement } = await marker

    googlemaps = {
      autocomplete: new AutocompleteService(),
      geocoding: new Geocoder(),
      map: Map,
      marker: AdvancedMarkerElement,
    }
  }

  const placePredictions = (
    input: string,
    countries: string | string[],
    types?: string[],
    exludeTypes: string[] = [],
  ): Promise<Nullable<google.maps.places.AutocompletePrediction[]>> => {
    return new Promise((resolve) => {
      googlemaps.autocomplete.getPlacePredictions(
        {
          input,
          componentRestrictions: {
            country: countries,
          },
          types,
        },
        (predictions, status) => {
          if (status !== 'OK') {
            resolve([])
            return
          }

          if (exludeTypes?.length) {
            // Exclude irrelevant types
            predictions = (predictions ?? []).filter((prediction) => {
              return !prediction.types.some((t) => exludeTypes.includes(t))
            })
          }

          resolve(predictions)
        },
      )
    })
  }

  const geocoder = ({ placeId, address }: { placeId?: string, address?: string }): Promise<google.maps.GeocoderResult[]> => {
    return new Promise((resolve, reject) => {
      googlemaps.geocoding.geocode(
        {
          address,
          placeId,
        },
        (results, status) => {
          if (status === 'OK' && results?.length) {
            resolve(results)
          }

          reject(new Error('Geocode was not successful for the following reason: ' + status))
        },
      )
    })
  }

  const map = (element: HTMLElement, mapOptions: google.maps.MapOptions = {}) => {
    return new googlemaps.map(element, mapOptions)
  }

  const advancedMarker = ({
    map,
    position,
    content,
  }: {
    map: google.maps.Map
    position: { lat: number, lng: number }
    content: HTMLElement
  }) => {
    return new googlemaps.marker({
      map,
      position,
      content,
    })
  }

  return {
    placePredictions,
    geocoder,
    map,
    advancedMarker,
  }
}
